.queryReissue .page-header .img {
  width: 45%;
  max-width: 183px;
  min-width: 165px;
}
.queryReissue__block {
  margin: 0;
  padding: 35px 15px;
  text-align: center;
}
.queryReissue__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 1100px;
}
.queryReissue__block .inner .t2_1 {
  margin-bottom: 40px;
}
.queryReissue__block .inner .reissue_contact {
  height: 72px;
  width: 500px;
  margin: 0 auto;
}
.queryReissue__questionnaire {
  margin: 50px 0;
  padding: 0 15px;
  position: relative;
  display: none;
  height: 0;
  visibility: hidden;
  opacity: 0;
}
.queryReissue__questionnaire::before, .queryReissue__questionnaire::after {
  content: "";
  display: none;
  width: 30px;
  height: 20px;
  background-image: url(../images/common/icon_arw_down.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -15px);
}
.queryReissue__questionnaire::before {
  bottom: 100%;
  transform: translate(-50%, -15px);
}
.queryReissue__questionnaire::after {
  top: 100%;
  transform: translate(-50%, 15px);
}
.queryReissue__questionnaire.open {
  display: block;
  height: auto;
  visibility: visible;
  opacity: 1;
}
.queryReissue__questionnaire.open::before {
  display: block;
}
.queryReissue__questionnaire.q1 {
  margin-top: 0;
}
.queryReissue__questionnaire.q1::before {
  display: none;
}
.queryReissue__questionnaire.q4.on::after {
  display: block;
}
.queryReissue__questionnaire .inner {
  padding: 20px 15px;
  max-width: 900px;
  text-align: center;
  background-color: #F1F6F9;
}
.queryReissue__questionnaire .questionnaire__q {
  margin: 0 auto;
  padding-left: 30px;
  width: 270px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  position: relative;
}
.queryReissue__questionnaire .questionnaire__q .icon_q {
  color: #0072BC;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  position: absolute;
  left: 0;
  top: -5px;
}
.queryReissue__questionnaire .questionnaire__a {
  margin-top: 25px;
}
.queryReissue__questionnaire .questionnaire__a .flexBox {
  display: flex;
  margin: 0 -10px;
}
.queryReissue__questionnaire .questionnaire__a .flexItem {
  padding: 0 10px;
  width: 50%;
}
.queryReissue__questionnaire .questionnaire__a a {
  width: 100%;
  font-size: 15px;
}
.queryReissue__bottom {
  display: none;
  padding-top: 0;
  padding-bottom: 50px;
}
.queryReissue__bottom.open {
  display: block;
}
.queryReissue__bottom .link {
  margin-top: 30px;
}
.queryReissue__bottom .link a.e_btn {
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  max-width: 345px;
}
.queryReissue__bottom .link a.e_btn span {
  display: block;
  text-align: center;
}
.queryReissue__bottom .link a.e_btn small {
  display: block;
  margin: 5px 0 0;
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
}
.queryReissue__bottom .link a.e_btn.first {
  align-items: center;
  height: 72px;
}
.queryReissue .e_btn.type_input {
  width: 100%;
  color: #0072BC;
  background: #fff;
  border: 1px solid #0072BC;
  transition: all 0.3s ease;
  cursor: pointer;
}
.queryReissue .e_btn.type_input::after {
  width: 14px;
  height: 14px;
  background-image: url(../images/common/icon_arw_btn3.svg);
  right: 10px;
  transform: translateY(-50%) rotate(90deg);
}
.queryReissue .e_btn.type_input:hover {
  color: #fff;
  background: #0072BC;
}
.queryReissue .e_btn.type_input:hover::after {
  background-image: url(../images/common/icon_arw_btn2.svg);
  right: 10px;
  top: 54%;
}
.queryReissue .e_btn.type_input.on {
  color: #fff;
  background: #0072BC;
  pointer-events: none;
}
.queryReissue .e_btn.type_input.on::after {
  background-image: url(../images/common/icon_arw_btn2.svg);
}
.queryReissue .e_btn.type_input.on:hover::after {
  top: 50%;
}

@media screen and (min-width: 768px) {
  .queryReissue .page-header .img {
    width: 100%;
    max-width: 365px;
  }
  .queryReissue__block {
    padding: 75px 15px 73px;
    text-align: center;
  }
  .queryReissue__questionnaire {
    margin: 80px 0;
    padding: 0 15px;
  }
  .queryReissue__questionnaire::after {
    transform: translate(-50%, 30px);
  }
  .queryReissue__questionnaire .inner {
    padding: 45px 60px 39px;
  }
  .queryReissue__questionnaire .questionnaire__q {
    display: inline-block;
    padding-left: 50px;
    width: auto;
    font-size: 24px;
    text-align: center;
  }
  .queryReissue__questionnaire .questionnaire__q .icon_q {
    font-size: 30px;
  }
  .queryReissue__questionnaire .questionnaire__a {
    margin-top: 32px;
  }
  .queryReissue__questionnaire .questionnaire__a .flexBox {
    display: flex;
    margin: 0 -25px;
  }
  .queryReissue__questionnaire .questionnaire__a .flexItem {
    padding: 0 25px;
    width: 50%;
  }
  .queryReissue__questionnaire .questionnaire__a a {
    font-size: 20px;
  }
  .queryReissue__bottom {
    padding-top: 0;
    padding-bottom: 100px;
  }
  .queryReissue__bottom .t1 {
    text-align: center;
  }
  .queryReissue__bottom .link a.e_btn {
    height: 72px;
    max-width: 436px;
  }
  .queryReissue__bottom .link a.e_btn small {
    margin: 10px 0 0;
  }
  .queryReissue__bottom .link a.e_btn.first {
    max-width: 531px;
    height: 100px;
  }
  .queryReissue .e_btn.type_input::after {
    width: 24px;
    height: 24px;
    right: 30px;
  }
  .queryReissue .e_btn.type_input:hover::after {
    right: 30px;
    top: 60%;
  }
  .queryReissue .e_btn.type_input.on:hover::after {
    top: 50%;
  }
}